import { h } from 'vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

const Home = () => import('@/views/Home.vue');
const UserProfile = () => import('@/views/Users/UserProfileView.vue');
const DashboardContainer = () => import('@/views/Dashboard/DashboardContainer.vue');
const MainLayout = () => import('@/Dashboard/layout/index.vue');

const PrivateRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/profile',
    name: 'public-profile',
    component: UserProfile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/coordinator',
    name: 'dashboard',
    component: MainLayout,
    meta: {
      requiresAuth: true,
      COORDINATOR_ONLY: true,
    },
    children: [
      {
        name: 'homePage',
        path: '',
        component: DashboardContainer,
      },
      {
        path: 'reports',
        name: 'reportsList',
        component: h(RouterView),
        children: [
          {
            path: '',
            name: 'reports',
            component: () => import('@/views/Dashboard/ReportsAdminView.vue'),
          },
          {
            path: '/reports/merge',
            name: 'reportMerge',
            component: () => import('@/views/Dashboard/MergeReportView.vue'),
          },
          {
            name: 'report-status',
            path: '/reports/:statusKey',
            component: () => import('@/views/Dashboard/ReportsByStatusIdAdminView.vue'),
          },
        ],
      },
      {
        path: 'users',
        name: 'usersList',
        component: h(RouterView),
        children: [
          {
            path: 'all',
            name: 'allUsers',
            component: () => import('@/views/Dashboard/AllUsersView.vue'),
          },
          {
            path: '',
            name: 'users',
            component: () => import('@/views/Dashboard/UsersView.vue'),
          },
          {
            path: 'coordinators',
            name: 'coordinators',
            component: () => import('@/views/Dashboard/CoordinatorsView.vue'),
          },
          {
            name: 'user',
            path: '/users/:userUuId',
            component: () => import('@/views/Users/UserProfileView.vue'),
          },
        ],
      },
      {
        path: 'profile',
        component: h(RouterView),
        children: [
          {
            name: 'user-profile',
            path: '/profile/:userUuId',
            component: () => import('@/views/User/UserProfile.vue'),
          },
          {
            name: 'myReports',
            path: '/my-reports',
            component: () => import('@/views/User/UserProfile.vue'),
          },
        ],
      },
      {
        name: 'license',
        path: '/license',
        component: () => import('@/views/License/LicenseOverview.vue'),
      },
      {
        name: 'setting',
        path: 'setting',
        component: h(RouterView),
        children: [
          {
            path: 'email',
            name: 'setting-email',
            component: () => import('@/views/Settings/CommuneEmailSettings.vue'),
          },
          {
            path: 'contact',
            name: 'setting-contact',
            component: () => import('@/views/Settings/CommuneContact.vue'),
          },
          {
            path: 'embedded',
            name: 'setting-embedded',
            component: () => import('@/views/Settings/CommuneEmbedded.vue'),
          },
          {
            path: 'commune',
            name: 'setting-commune',
            component: () => import('@/views/Settings/CommuneSettings.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/admin',
    name: 'admin',
    component: MainLayout,
    meta: {
      requiresAuth: true,
      ADMIN_ONLY: true,
    },
    children: [
      {
        name: 'adminDashboard',
        path: '',
        component: DashboardContainer,
      },
      {
        name: '',
        path: 'communes',
        component: h(RouterView),
        children: [
          {
            path: '',
            name: 'communes',
            component: () => import('@/views/Dashboard/CommunesAdminPage.vue'),
            props: (route) => ({ query: route.query }),
          },
          {
            name: 'commune',
            path: '/commune/:uuid',
            component: () => import('@/views/Commune/CommuneView.vue'),
          },
          {
            name: 'pendingCommunes',
            path: '/pending',
            component: () => import('@/views/Commune/PendingCommunes.vue'),
          },
          {
            name: 'pendingCommune',
            path: '/pending/:tempId',
            component: () => import('@/views/Commune/PendingCommune.vue'),
          },
        ],
      },
      {
        name: 'tools',
        path: 'tools',
        component: h(RouterView),
        children: [
          {
            path: 'export',
            name: 'export',
            component: () => import('@/views/Admin/AdminExport.vue'),
          },
        ],
      },
      {
        name: 'newCommuneGeojson',
        path: '/new-geojson',
        component: () => import('@/views/Commune/NewCommuneGeojson.vue'),
      },
      {
        name: 'communesMissingGeoJson',
        path: '/commune-missing-geojson',
        component: () => import('@/views/Commune/CommunesMissingGeoJson.vue'),
      },
    ],
  },
  // {
  //   name: 'commune',
  //   path: '/commune/:uuid',
  //   component: import('@/views/Commune/CommuneView.vue'),
  // },
];

export { PrivateRoutes };
