<script lang="ts" setup>
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

/**
 *
 * Component Description:Desc
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
const { locale } = useI18n();
const $TheFooterHtml = ref('loading');

const fetchFooter = async () => {
  const res = await fetch(`/base/footer_${locale.value}.html`);
  $TheFooterHtml.value = await res.text();
};

/*----------  Navigation related  ----------*/
watch(
  locale,
  () => {
    fetchFooter();
  },
  { immediate: true },
);
</script>

<template>
  <div class="rd-bg-base-961" v-html="$TheFooterHtml"></div>
</template>
<style scoped lang="scss">
::v-deep(.container) {
  @apply rd-container xl:rd-max-w-7xl rd-mx-auto rd-text-lg rd-px-2 rd-py-12 md:rd-px-0 dark:rd-text-base-900 rd-text-base-100;

  .row {
    @apply rd-flex rd-flex-col-reverse rd-py-4 lg:rd-py-0 md:rd-grid md:rd-grid-cols-2 rd-gap-4;
  }

  .order-1 {
    @apply rd-flex rd-flex-col xl:rd-justify-center rd-order-1 md:rd-justify-end;
  }

  .order-2 {
    @apply rd-text-sm;

    h2 {
      @apply rd-mb-1 rd-mt-6 rd-text-lg md:rd-text-2.0xl md:rd-mb-4 rd-tracking-wider;
    }

    p {
      @apply md:rd-text-sm md:rd-max-w-full rd-text-base-110 rd-font-normal rd-tracking-wide rd-mb-4;
    }
  }

  .copyright {
    opacity: 0.8;

    a {
      @apply rd-text-base-910;
    }
  }

  li {
    list-style: none;
  }

  .nav {
    @apply rd-mt-auto rd-mb-5 md:rd-mb-auto md:rd-mt-5 rd-font-extralight rd-font-sans;
  }

  .navbar-nav,
  .social-icons {
    @apply rd-flex rd-flex-row rd-justify-center rd-flex-wrap rd-mb-auto md:rd-mb-0 md:rd-justify-end;
  }

  .social-icons {
    @apply rd-mt-0 md:rd-mt-auto md:rd-pt-[8.15rem];
  }

  .navbar-nav {
    @apply rd-flex-col rd-text-center xl:rd-text-right dark:rd-text-base-100;

    .nav-link {
      @apply hover:rd-underline hover:rd-transition-all hover:rd-text-base-910;
    }
  }

  li {
    @apply rd-inline-block rd-my-1;
  }

  [class*='icon-'] {
    @apply rd-w-7 rd-h-7 rd-bg-white rd-rounded-full rd-text-base-961 rd-grid rd-place-content-center rd-ml-[1.19rem];
  }

  .icon-facebook {
    margin-left: unset;
  }
}
</style>
