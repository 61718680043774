import { fromZonedTime, toZonedTime, format } from 'date-fns-tz';
export interface transformDateParams {
  date: string;
  timeZone?: string;
  timeFormat?: string;
}
export const communeDateTimeZoneToUTC = ({
  date,
  timeZone = 'UTC',
  timeFormat = "yyyy-MM-dd'T'HH:mm:ss",
}: transformDateParams) => {
  return format(toZonedTime(date, timeZone), timeFormat);
};
export const utcToCommuneDateTimeZone = ({
  date,
  timeZone = 'UTC',
  timeFormat = "yyyy-MM-dd'T'HH:mm:ss",
}: transformDateParams) => {
  return format(fromZonedTime(date, timeZone), timeFormat);
};
