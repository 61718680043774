<script lang="ts" setup>
import { IconLocal } from '@/Dashboard';
import { useCommuneStore } from '@/stores/CommuneStore';
import { ref, toRefs } from 'vue';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import { useRoute } from 'vue-router';

/**
 *
 * Communes Table actions
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

interface Props {
  headerActions: boolean;
  sendEmail: boolean;
  communeUuid: string;
  showSetActive?: boolean;
  showDelete?: boolean;
}

type TableEventEmitterKey = 'DELETE' | 'OPEN' | 'EMAIL' | 'EDIT' | 'SET_ACTIVE' | 'FORCE_SYNC' | 'SYNC_REPORTS';

// Define emitter for the table container to Handle
const $emit = defineEmits<{ (e: 'trigger', key: TableEventEmitterKey): void }>();

const props = withDefaults(defineProps<Props>(), {
  showSetActive: true,
  showDelete: true,
});
const { headerActions, communeUuid, showSetActive, showDelete } = toRefs(props);

/*----------  Store  ----------*/
const communeStore = useCommuneStore();
const { selectedCommune } = storeToRefs(communeStore);

const SwitchIcon = ref<'active' | 'inactive'>('inactive');

watch(
  selectedCommune,
  () => {
    const isSelectedCommune = communeUuid.value === selectedCommune.value?.uuid;
    SwitchIcon.value = selectedCommune.value && isSelectedCommune ? 'active' : 'inactive';
  },
  {
    immediate: true,
  },
);

const route = useRoute();
const haveReload = ref(route.query.showReload);
</script>

<template>
  <div v-if="headerActions" class="rd-flex rd-justify-end dark:rd-text-base-110 rd-text-opacity-60 row__actions">
    <n-tooltip size="tiny" :bordered="false" :theme-overrides="{ padding: '0px 10px' }" :placement="'left-end'" :arrow="false">
      <template #trigger>
        <n-button class="rd-mr-[10px] rd-text-inherit" text circle size="tiny" @click="$emit('trigger', 'SYNC_REPORTS')">
          <template #icon>
            <IconLocal type="SyncIcon" size="14" />
          </template>
        </n-button>
      </template>
      {{ $t('admin.commune.sync.reports.tooltip.body') }}
    </n-tooltip>

    <n-tooltip v-if="showSetActive" size="tiny" :bordered="false" :theme-overrides="{ padding: '0px 10px' }">
      <template #trigger>
        <n-button class="rd-mr-[10px] rd-text-inherit" text circle size="tiny" @click="$emit('trigger', 'SET_ACTIVE')">
          <template #icon>
            <IconLocal v-if="SwitchIcon === 'active'" type="SwitchIconActive" size="17" />
            <IconLocal v-else type="SwitchIcon" size="13" />
          </template>
        </n-button>
      </template>
      {{ $t('admin.commune.set.active.tooltip.body') }}
    </n-tooltip>

    <n-button class="rd-mr-[10px] rd-text-inherit" text circle size="tiny" @click="$emit('trigger', 'EDIT')">
      <template #icon>
        <IconLocal type="EditIcon" size="13" />
      </template>
    </n-button>
    <n-button class="rd-mr-[10px] rd-text-inherit" text circle size="tiny" @click="$emit('trigger', 'DELETE')">
      <template #icon>
        <IconLocal type="DeleteIcon" size="14" />
      </template>
    </n-button>
    <n-button
      v-if="haveReload"
      class="rd-mr-[10px] rd-text-inherit"
      text
      circle
      size="tiny"
      @click="$emit('trigger', 'FORCE_SYNC')"
    >
      <template #icon>
        <IconLocal type="ReloadIcon" size="14" />
      </template>
    </n-button>
  </div>
</template>
<style scoped></style>
