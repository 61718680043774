<script lang="ts" setup>
import { IconLocal } from '@/Dashboard';
import { useCommuneStore } from '@/stores/CommuneStore';
import { ref, toRefs } from 'vue';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import { useRoute } from 'vue-router';

/**
 *
 * Communes Table actions
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

type TableEventEmitterKey = 'DELETE' | 'OPEN' | 'EMAIL' | 'EDIT' | 'SET_ACTIVE' | 'FORCE_SYNC';

// Define emitter for the table container to Handle
const $emit = defineEmits<{ (e: 'trigger', key: TableEventEmitterKey): void }>();
</script>

<template>
  <div class="rd-flex rd-justify-end dark:rd-text-base-110 rd-text-opacity-60 row__actions">
    <n-button class="rd-mr-[10px] rd-text-inherit" text circle size="tiny" @click="$emit('trigger', 'EDIT')">
      <template #icon>
        <IconLocal type="EditIcon" size="13" />
      </template>
    </n-button>
  </div>
</template>
<style scoped></style>
