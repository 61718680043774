import { REPORT_STATUS } from './../../typings/api/ReportStatus';
import { Ref, ref, UnwrapRef } from 'vue';

export interface Result<T> {
  data: Ref<UnwrapRef<T | null>>;
  error: Ref<Error | null>;
  loading: Ref<boolean>;
}

export interface Menu {
  id: string;
  label: string;
  icon?: string;
  name?: string;
  params?: { [key: string]: string };
  query?: { [key: string]: string };
  children?: Menu[];
  countLabel?: string;
}

const BaseMenu: Menu[] = [
  {
    id: '110159148224d543ae4e05c5',
    label: 'sidebar.dashboard',
    icon: 'RadarIconDashboard',
    name: 'homePage',
  },
  {
    id: '610159148224d543ae4e05c6',
    label: 'sidebar.reports.main',
    icon: 'RadarIconReports',
    children: [
      {
        id: '610159148224d543ae4e05c4',
        icon: 'AllMarkerIcon',
        label: 'sidebar.reports.all',
        name: 'reports',
        countLabel: 'total',
      },
      {
        id: '610159148224d543ae4e05c5',
        icon: 'MarkerNew',
        label: 'sidebar.reports.new',
        name: 'report-status',
        countLabel: REPORT_STATUS.NEW,
        params: { statusKey: REPORT_STATUS.NEW },
      },
      {
        id: '610159148224d543ae4e05c7',
        icon: 'MarkerProgress',
        label: 'sidebar.reports.onProgress',
        name: 'report-status',
        countLabel: REPORT_STATUS.IN_PROGRESS,
        params: { statusKey: REPORT_STATUS.IN_PROGRESS },
      },
      {
        id: '610159148224d543ae4e05c8',
        icon: 'MarkerDone',
        label: 'sidebar.reports.done',
        name: 'report-status',
        countLabel: REPORT_STATUS.DONE,
        params: { statusKey: REPORT_STATUS.DONE },
      },
      {
        id: '610159148224d543ae4e05c10',
        icon: 'MarkerNeutral',
        label: 'sidebar.reports.neutral',
        name: 'report-status',
        countLabel: REPORT_STATUS.NEUTRAL,
        params: { statusKey: REPORT_STATUS.NEUTRAL },
      },
      {
        id: '610159148224d543ae4e05c9',
        icon: 'MarkerPositive',
        label: 'sidebar.reports.positive',
        name: 'report-status',
        countLabel: REPORT_STATUS.POSITIVE,
        params: { statusKey: REPORT_STATUS.POSITIVE },
      },
    ],
  },
  {
    id: '210159148224d543ae4e05c5',
    label: 'sidebar.myReports',
    icon: 'RadarIconMineReports',
    name: 'myReports',
  },
  {
    id: '910159148224d543ae1e05c7',
    label: 'sidebar.settings.main',
    icon: 'RadarIconConfiguration',
    children: [
      { id: '910159148224d543ae4e0511', label: 'sidebar.settings.email', name: 'setting-email' },
      { id: '910159148224d543ae4e0512', label: 'sidebar.settings.contact', name: 'setting-contact' },
      { id: '910159148224d543ae4e0513', label: 'sidebar.settings.embedded', name: 'setting-embedded' },
      { id: '910159148224d543ae4e0514', label: 'sidebar.settings.commune', name: 'setting-commune' },
    ],
  },
];
const EndMenu: Menu[] = [
  {
    id: '100159148224d543ae4e05c5',
    label: 'sidebar.license',
    icon: 'RadarIconLicense',
    name: 'license',
  },
];

const adminSidebar: Menu[] = [
  {
    id: '710159148224d543ae4e05c7',
    label: 'sidebar.users.main',
    icon: 'RadarIconUser',
    children: [
      { id: '710159148224d543ae4e0511', label: 'sidebar.users.all', name: 'users', countLabel: 'usersTotal' },
      {
        id: '710159148224d543ae4e0512',

        label: 'sidebar.users.coordinators',
        name: 'coordinators',
        countLabel: 'coordinatorsTotal',
      },
      {
        id: '710159148224d543ae4e0513',

        label: 'sidebar.users.allSystem',
        name: 'allUsers',
        countLabel: 'usersTotal',
      },
    ],
  },
  {
    id: '620159148224d543ae4e05c7',
    label: 'sidebar.communes.main',
    icon: 'RadarIconCommune',
    children: [
      {
        id: '620159148224d543ae4e0511',

        label: 'sidebar.communes.all',
        name: 'communes',
        query: { by: 'all' },
      },
      {
        id: '620159148224d543ae4e0211',
        label: 'sidebar.communes.pending',
        name: 'pendingCommunes',
      },
    ],
  },

  {
    id: '810159148224d543ae4e05c7',
    label: 'sidebar.tools.main',
    icon: 'RadarIconExport',
    name: 'export',
    // children: [{ id: '810159148224d543ae4e0512', label: 'sidebar.tools.export', name: 'export' }],
  },
];
const sidebarMenu = ref<Menu[]>([
  ...BaseMenu,
  {
    id: '710159148224d543ae4e05c7',
    label: 'sidebar.users.main',
    icon: 'RadarIconUser',
    children: [
      { id: '710159148224d543ae4e0511', label: 'sidebar.users.all', name: 'users' },
      {
        id: '710159148224d543ae4e0512',
        label: 'sidebar.users.coordinators',
        name: 'coordinators',
        countLabel: 'coordinatorsTotal',
      },
    ],
  },
  ...EndMenu,
]);

export const setAdminSideBar = () => {
  sidebarMenu.value = [...BaseMenu, ...adminSidebar, ...EndMenu];
};
export const setCoordinatorSideBar = () => {
  sidebarMenu.value = [
    ...BaseMenu,
    {
      id: '710159148224d543ae4e05c7',
      label: 'sidebar.users.main',
      icon: 'RadarIconUser',
      children: [
        { id: '710159148224d543ae4e0511', label: 'sidebar.users.all', name: 'users' },
        {
          id: '710159148224d543ae4e0512',
          label: 'sidebar.users.coordinators',
          name: 'coordinators',
          countLabel: 'coordinatorsTotal',
        },
      ],
    },
    ...EndMenu,
  ];
};

export const useMenus = (type: 'main' | 'shortcut' = 'main'): Result<Menu[]> => {
  return {
    data: sidebarMenu,
    error: ref(null),
    loading: ref(false),
  };
};
