<script lang="ts" setup>
import { useCommuneStore } from '@/stores/CommuneStore';
import { storeToRefs } from 'pinia';
import { toRefs } from 'vue';

/**
 *
 * Render the commune state
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
interface Props {
  stateId: number;
}

const props = defineProps<Props>();
const { stateId } = toRefs(props);

const communeStore = useCommuneStore();
const { GET_All_FEDERAL_STATE } = storeToRefs(communeStore);
</script>

<template>
  <div>{{ GET_All_FEDERAL_STATE.get(stateId)?.label || '--' }}</div>
</template>
<style scoped></style>
