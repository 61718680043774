import { useReporterMenu } from './../menus/MapHeaderMenu';
import { DeepRequired } from '@/stores/GlobalStore';
import { useCoordinatorMenu } from '@/menus/MapHeaderMenu';
import { i18n } from '@/i18n';
import { KeycloakProfile } from 'keycloak-js';
import { defineStore } from 'pinia';
import RoadReport from '@/services/ReportService';

import {
  ReportDto,
  V1ReportParameters,
  UserProfileUpdateDto,
  V1CommuneUuidLicenceParameters,
  LicenceDto,
  V1CommuneUuidLicenceRequest,
  UserCreationDto,
} from '@/typings/api';
import { useReportsAllStore } from '@/stores/AllReportsStore';
import { KeycloakUserClass, keycloakUserDetails, UserProfileDtoClass } from '@/typings/dto/UserDto';
import { PaginationInfo } from 'naive-ui';
import { setAdminSideBar } from '@/Dashboard/composables';
import { UsersService, UserValidationParam } from '@/services/UsersService';
import { ReportServiceCommune } from '@/services/ReportServiceCommune';
import { AuthUserService } from '@/services/AuthUserService';
import { setCoordinatorSideBar } from '@/Dashboard/composables/Menus';
import { useOidcStore } from 'vue3-oidc';
import router from '@/router';
import { REPORT_STATUS } from '@/typings/api/ReportStatus';
import { type RemovableRef, useLocalStorage } from '@vueuse/core';

export type UserReportStats = Record<REPORT_STATUS | 'MINE', number>;
export interface AuthTokenInterface {
  aud: string;
  jti: string;
  iat: number;
  nbf: number;
  exp: number;
  sub: string;
  scopes: Array<'radar' | 'radar-administrator' | 'radar-reporter' | 'radar-coordinator' | 'sr-admin'>;
  username: string;
  iss: string;
}

interface UserStoreInterface {
  user: UserType | null;
  currentUserReportStats: Record<REPORT_STATUS | 'MINE', number>;
  currentReportsPagination: RemovableRef<PaginationInfo>;
  totalReports: number;
  keycloakUserDetails: KeycloakUserClass;
  userDetails: UserProfileDtoClass;

  communeLicenseDetails: Required<DeepRequired<LicenceDto>>;
  token: null | { payload: AuthTokenInterface };

  coordinatorUserModalTermAndConditions: boolean;
}

export type UserType = KeycloakProfile & { sub: string; locale: string };
export const useUserStore = defineStore({
  id: 'user',
  state: (): UserStoreInterface => ({
    user: null,
    userDetails: new UserProfileDtoClass(),
    currentUserReportStats: {
      NEW: 0,
      IN_PROGRESS: 0,
      DONE: 0,
      NEUTRAL: 0,
      POSITIVE: 0,
      MINE: 0,
    },
    totalReports: 0,
    keycloakUserDetails: new KeycloakUserClass(),
    currentReportsPagination: useLocalStorage('REPORT_PAGINATION', {
      page: 1,
      pageSize: 20,
      endIndex: 0,
      pageCount: 1,
      startIndex: 0,
      itemCount: 10,
    }),
    communeLicenseDetails: {
      contractData: {
        reportStart: '',
        reportEnd: '',
        adminStart: '',
        adminEnd: '',
      },
      id: 0,
      description: '',
      name: '',
    },
    token: null,
    coordinatorUserModalTermAndConditions: false,
  }),
  getters: {
    GET_CURRENT_USER: (state) => state.user,
    GET_CURRENT_KEYCLOAK_USER: (state): KeycloakUserClass => state.keycloakUserDetails,
    GET_CURRENT_USER_REPORTS_STATS: (state) => {
      return state.currentUserReportStats;
    },
    GET_CURRENT_PAGINATION: (state) => {
      return state.currentReportsPagination;
    },
  },
  actions: {
    ACTION_SET_PAGINATION(p: PaginationInfo) {
      this.currentReportsPagination = p;
    },
    async SET_CURRENT_ACTIVE_USER(userInformation: KeycloakProfile) {
      this.user = userInformation as UserType;
      i18n.global.locale.value = (userInformation as UserType).locale
        ? // ? (userInformation as UserType).locale.replaceAll('-DE', '').replaceAll('-EN', '')
          'de'
        : 'de';

      const res = await UsersService.GetUserProfile();
      if (res.status === 200) {
        this.userDetails = new UserProfileDtoClass(res.data);
        await this.userDetails.setAssignedCommune();
      }

      // check if user is coordinator and is the first time that he log in and the term and conditions is false
      if (this.keycloakUserDetails.isCoordinator && !this.userDetails.termAndConditions) {
        this.coordinatorUserModalTermAndConditions = true;
      }
    },
    async FETCH_USER_DETAILS_FROM_AUTH(uuid: string, scopes: AuthTokenInterface['scopes']) {
      const res = await AuthUserService.FetchUserInformation();

      if (res.status === 200) {
        try {
          this.SET_CURRENT_ACTIVE_KEYCLOAK_USER({
            id: uuid || '',
            fullName: res.data.email || '',
            username: res.data.username || '',
            roles: {
              'radar-administrator': scopes.includes('radar-administrator'),
              'radar-coordinator': scopes.includes('radar-coordinator'),
              'radar-reporter': scopes.includes('radar-reporter'),
            },
          });
          await this.SET_CURRENT_ACTIVE_USER({ ...res.data });
        } catch (error) {
          return error;
        }
      }
    },
    async ACTION_FETCH_PROFILE() {
      try {
        const res = await UsersService.GetUserProfile();
        if (res.status === 200) {
          this.userDetails = new UserProfileDtoClass(res.data);
          await this.userDetails.setAssignedCommune();
        }
      } catch (error) {
        const syncUserProfile = await UsersService.SyncUserProfile({ uuid: this.keycloakUserDetails.id });
        if (syncUserProfile.status === 200) {
          this.userDetails = new UserProfileDtoClass(syncUserProfile.data);
          await this.userDetails.setAssignedCommune();
          this.ACTION_REFRESH_TOKEN();
        }
      }
    },
    async ACTION_UPDATE_PROFILE(d: UserProfileUpdateDto) {
      const res = await UsersService.UpdateUserProfile({ data: d });
      if (res.status === 200) {
        this.userDetails = new UserProfileDtoClass(res.data);
        await this.userDetails.setAssignedCommune();
        this.userDetails.termAndConditions ? (this.coordinatorUserModalTermAndConditions = false) : null;
      }
    },
    async ACTION_DELETE_CURRENT_USER() {
      return await UsersService.DeleteCurrentUser();
    },
    async ACTION_DELETE_USER({ uuid }: { uuid: string }) {
      return await UsersService.DeleteUser({ uuid });
    },
    SET_CURRENT_ACTIVE_KEYCLOAK_USER(userInformation: keycloakUserDetails) {
      this.keycloakUserDetails = new KeycloakUserClass(userInformation);

      useReporterMenu();
      this.keycloakUserDetails.isCoordinator || this.keycloakUserDetails.isAdmin ? useCoordinatorMenu() : null;
      setCoordinatorSideBar();
      this.keycloakUserDetails.isAdmin ? setAdminSideBar() : null;
    },

    ACTION_SET_CURRENT_USER_STATS(stats: UserReportStats) {
      this.currentUserReportStats = stats;
    },
    async ACTION_FETCH_REPORTS_FOR_CURRENT_USER(params: V1ReportParameters, recursiveCall = false) {
      const { status, data } = await RoadReport.GetReportByUser(params);
      if (status === 200) {
        const baseReportStore = useReportsAllStore();
        this.totalReports = data.totalElements;
        baseReportStore.ACTION_SET_BASE_REPORTS(data.content, true);

        this.currentReportsPagination.itemCount = data.totalElements;
        this.currentReportsPagination.page = data.number;
        this.currentReportsPagination.pageCount = data.totalPages;
        this.currentReportsPagination.pageSize = data.size;

        // if (!this.user) return;
        /*
         * Return if the current user information is not set
         * If we have the current user information then we can format the stats
         * so we can use the stats in my report menu entry
         * */
        // const stats = data.content.reduce(
        //   (acc: UserReportStats, value: ReportDto) => {
        //     if (value.statusKey) acc[value.statusKey] = acc[value.statusKey] + 1;
        //     return acc;
        //   },
        //   {
        //     NEW: 0,
        //     IN_PROGRESS: 0,
        //     DONE: 0,
        //     NEUTRAL: 0,
        //     POSITIVE: 0,
        //     MINE: 0,
        //   }
        // );

        // this.ACTION_SET_CURRENT_USER_STATS(stats);

        // Make recursive call to get all reports
        if (recursiveCall) {
          for (let index = 1; index < data.totalPages; index++) {
            const { status, data: newData } = await RoadReport.GetReportByUser({ ...params, page: index });
            status === 200 ? baseReportStore.ACTION_SET_BASE_REPORTS_PUSH(newData.content) : null;
          }
        }
      }
    },

    async ACTION_FETCH_COMMUNE_LICENSE_COORDINATOR(p: V1CommuneUuidLicenceParameters) {
      const res = await ReportServiceCommune.GetCommuneLicenseFromRoadReportService(p);

      if (res.status === 200) {
        this.communeLicenseDetails = res.data;
      }
      return res;
    },
    async ACTION_UPDATE_COMMUNE_REPORTING_PERIOD(
      p: V1CommuneUuidLicenceParameters & {
        data: V1CommuneUuidLicenceRequest;
      },
    ) {
      const res = await ReportServiceCommune.UpdateCommuneLicenseFromRoadReportService(p);

      if (res.status === 200) {
        this.communeLicenseDetails = res.data;
      }
    },

    async ACTION_REFRESH_TOKEN() {
      const { state, actions } = useOidcStore();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!state.value && state.value.user && state.value.user.refresh_token) return 404;
      try {
        const request = await AuthUserService.manualRefreshToken({ refreshToken: state.value.user?.refresh_token as string });
        if (request.status === 200 && state.value.user) {
          const user = await state.value.userManager?.getUser();
          if (user === null || !user) return;
          user.access_token = request.data.access_token as string;
          user.refresh_token = request.data.refresh_token as string;
          actions.value.setUser(user);
          await state.value.userManager?.storeUser(user);
        } else {
          actions.value.removeUser();
          this.token = null;
          this.keycloakUserDetails = new KeycloakUserClass();
          router.push('/public-map');
        }
      } catch (error) {
        actions.value.removeUser();
        this.token = null;
        this.keycloakUserDetails = new KeycloakUserClass();
        router.push('/public-map');

        return 404;
      }
    },

    async ACTION_USER_REGISTRATION({ data }: { data: UserCreationDto }) {
      const res = await UsersService.RegisterUser({ data });
      return res;
    },
    async ACTION_EMAIL_VERIFICATION(token: string, isDelete = false) {
      const res = await UsersService.VerifyEmail({ token, isDelete });
      return res;
    },
    async ACTION_EMAIL_VERIFICATION_RESEND(email: string) {
      const res = await UsersService.SendEmailVerification(email);
      return res;
    },
    async ACTION_SEND_PASSWORD_RESET_EMAIL({ email }: { email: string }) {
      const res = await UsersService.SendPasswordResetEmail(email);
      return res;
    },
    async ACTION_RESET_PASSWORD({ password, token }: { password: string; token: string }) {
      const res = await UsersService.ResetUserPassword({ password, token });
      return res;
    },
    async ACTION_USER_CHECK_USERNAME(p: UserValidationParam) {
      const res = await UsersService.CheckUsernameOrEmail(p);
      return res;
    },
  },
});
