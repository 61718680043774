import { DeepRequired } from '@/stores/GlobalStore';
import {
  CommuneDetailsDto,
  LicenceDto,
  MailBodyPartsConfigurationDto,
  MailCustomContentRequestDto,
  V1CommuneUuidInfoParameters,
  V1CommuneUuidLicenceParameters,
  V1CommuneUuidLicenceRequest,
  CommuneRegistrationDto,
  V1CommuneregisterTempIdParameters,
  V1CommuneregisterParameters,
  V1CommuneUuidSyncParameters,
} from '@/typings/api';
import axios, { AxiosResponse } from 'axios';
import { EmailConfigOptions, EmailConfigOptionsEditable } from '@/stores/CommuneEmailSetting';
import { useOidcStore } from 'vue3-oidc';
import { Page } from '@/typings/general/Page';

export interface GetEmailParams {
  communeUuid: string;
  type: EmailConfigOptions;
  system?: boolean;
}
export interface UpdateEmailParams {
  communeUuid: string;
  reset?: boolean;
  type: EmailConfigOptions;
  data: MailCustomContentRequestDto;
}

export interface PendingCommuneOverview {
  tempId: number;
  manualName: string;
  externalId: string;
  adminStart: string;
  adminEnd: string;
  reportStart: string;
  reportEnd: string;
  licenseId: number;
}

export abstract class ReportServiceCommune {
  private static ROAD_REPORT_INSTANCE = axios.create({
    baseURL: `${import.meta.env.VITE_ROAD_REPORT}/api/v1`,
  });

  /*----------  Get Commune license from Road Report service  ----------*/
  static async GetCommuneLicenseFromRoadReportService({
    uuid,
  }: V1CommuneUuidLicenceParameters): Promise<AxiosResponse<Required<DeepRequired<LicenceDto>>>> {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.get(`/commune/${uuid}/licence`);
  }
  static async UpdateCommuneLicenseFromRoadReportService({
    uuid,
    data,
  }: V1CommuneUuidLicenceParameters & {
    data: V1CommuneUuidLicenceRequest;
  }): Promise<AxiosResponse<Required<DeepRequired<LicenceDto>>>> {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.patch(`/commune/${uuid}/licence`, data);
  }

  static async CommuneInformationUpdate({
    uuid,
    data,
    scope = 'none',
  }: V1CommuneUuidInfoParameters & { data: DeepRequired<CommuneDetailsDto>; scope?: string }): Promise<
    AxiosResponse<Required<DeepRequired<CommuneDetailsDto>>>
  > {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.put(`/commune/${uuid}/info?scope=${scope}`, data);
  }
  static async GetCommuneInformation({
    uuid,
    scope = 'none',
  }: {
    uuid: string;
    scope?: string;
  }): Promise<AxiosResponse<Required<DeepRequired<CommuneDetailsDto>>>> {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.get(`/commune/${uuid}/info?scope=${scope}`);
  }

  /*----------  Get commune email configuration  ----------*/
  static async GetEmailConfigurations({
    communeUuid,
    ...p
  }: GetEmailParams): Promise<AxiosResponse<Record<EmailConfigOptionsEditable | 'type' | 'communeUuid', string>>> {
    this.tokenInterceptor();
    const urlParams = new URLSearchParams(p as unknown as Record<string, string>);

    return this.ROAD_REPORT_INSTANCE.get(`/commune/${communeUuid}/email?${urlParams.toString()}`);
  }
  static async ResetEmailConfiguration({
    communeUuid,
    reset = false,
    data,
  }: UpdateEmailParams): Promise<AxiosResponse<Record<EmailConfigOptionsEditable | 'type', string>>> {
    this.tokenInterceptor();

    return this.ROAD_REPORT_INSTANCE.put(`/commune/${communeUuid}/email?reset=${reset}`, data);
  }

  static async GetGlobalEmailConfiguration(): Promise<AxiosResponse<Required<MailBodyPartsConfigurationDto>>> {
    this.tokenInterceptor();
    return this.ROAD_REPORT_INSTANCE.get(`/commune/email`);
  }
  static async RegisterCommune({ commune }: { commune: CommuneRegistrationDto }) {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.put(`/commune/register`, commune);
  }
  static async GetAllPendingCommunes(p: V1CommuneregisterParameters): Promise<AxiosResponse<Page<PendingCommuneOverview>>> {
    const urlParams = new URLSearchParams(p as Record<string, string>);

    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.get(`/commune/register?${urlParams.toString()}`);
  }
  static async GetPendingCommuneByUuId({
    tempId,
  }: V1CommuneregisterTempIdParameters): Promise<AxiosResponse<Required<CommuneRegistrationDto>>> {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.get(`/commune/register/${tempId}`);
  }

  static async UpdatePendingCommune({
    tempId,
    communeDetails,
  }: V1CommuneregisterTempIdParameters & { communeDetails: CommuneRegistrationDto }): Promise<
    AxiosResponse<Required<CommuneRegistrationDto>>
  > {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.put(`/commune/register/${tempId}`, communeDetails);
  }

  static async ConfirmCommune({ tempId }: V1CommuneregisterTempIdParameters) {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.post(`/commune/register/${tempId}/finalize`);
  }

  static async DeletePendingCommune({ tempId }: V1CommuneregisterTempIdParameters) {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.delete(`/commune/register/${tempId}`);
  }
  static async SyncCommuneByUuId({ uuid, action = 'UPDATE' }: V1CommuneUuidSyncParameters) {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.put(`/commune/${uuid}/sync?action=${action}`);
  }
  static async SyncAllReportToAndFrom({ uuid }: V1CommuneUuidSyncParameters) {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.post(`/commune/sync/${uuid}`);
  }
  static async GetAllCommunesThatNeedSync() {
    this.tokenInterceptor();
    return await this.ROAD_REPORT_INSTANCE.get(`/commune/sync`);
  }

  private static tokenInterceptor() {
    const { state } = useOidcStore();
    this.ROAD_REPORT_INSTANCE.interceptors.request.use(
      (config) => {
        if (state.value.token) {
          config.headers.Authorization = `Bearer ${state.value.token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }
}
