<script lang="ts" setup>
/**
 *
 * Component Description:Desc
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { ThemeCustom, DarkThemeCustom, UseTheme } from '@/settings/ThemeOverrides';
import {
  darkTheme,
  enUS,
  deDE,
  NConfigProvider,
  NDialogProvider,
  dateDeDE,
  dateEnUS,
  GlobalIconConfig,
  NNotificationProvider,
} from 'naive-ui';
import 'vfonts/Roboto.css';
import { computed, h } from 'vue';
import { useI18n } from 'vue-i18n';
import { IconLocal } from '..';
import { useOidcStore } from 'vue3-oidc';
const { locale } = useI18n();
const theme = computed(() => {
  const appThemeValue = UseTheme.value === 'darkTheme' ? darkTheme : null;
  document.documentElement.setAttribute('data-theme', UseTheme.value === 'darkTheme' ? 'rd-dark' : '');
  return appThemeValue;
});

const breakpoints: Record<string, number> = {
  xs: 0,
  s: 640,
  m: 1024,
  l: 1280,
  xl: 1536,
  xxl: 1920,
};

deDE.DatePicker.confirm = 'Auswählen';

const IconsOverride: GlobalIconConfig = {
  clear: () => h(IconLocal, { type: 'CloseIcon' }),
  date: () => h(IconLocal, { type: 'CalendarIcon', class: 'rd-text-base-990 dark:rd-text-base-100 dark:rd-opacity-40' }),
};

const { state } = useOidcStore();
state.value.userManager?.startSilentRenew();
</script>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MainProvider',
});
</script>

<template>
  <n-config-provider
    :theme-overrides="UseTheme === 'darkTheme' ? DarkThemeCustom : ThemeCustom"
    :locale="locale === 'de' ? deDE : enUS"
    :date-locale="locale === 'de' ? dateDeDE : dateEnUS"
    :breakpoints="breakpoints"
    :theme="theme"
    :icons="IconsOverride"
  >
    <n-global-style />
    <n-loading-bar-provider>
      <n-notification-provider :placement="'bottom-right'">
        <n-message-provider>
          <n-dialog-provider>
            <slot />
          </n-dialog-provider>
        </n-message-provider>
      </n-notification-provider>
    </n-loading-bar-provider>
  </n-config-provider>
</template>
<style scoped></style>
